import { KanbanBoardCreditos } from ".";
import { useKanbanNegociosCreditosContext } from "../../../Hooks/useKanbanNegociosCredito";

export const KanbanToCreditosPage = () => {
  const {
    loading,
    onDragEnd,
    filteredKanbanList,
    users,
    setUsuarioId,
    usuarioId,
    isAdminCredito,
    kanbanList,
    setKanbanList
  } = useKanbanNegociosCreditosContext();

  return <KanbanBoardCreditos
    isDraggable={true}
    loading={loading}
    onDragEnd={onDragEnd}
    filteredKanbanList={filteredKanbanList}
    users={users}
    setUsuarioId={setUsuarioId}
    usuarioId={usuarioId}
    isAdminCredito={isAdminCredito}
    showSearchInput={true}
    kanbanList={kanbanList}
    setKanbanList={setKanbanList}
  />
}