import React from 'react';
import { GenericLoading } from '../../GerenicLoading';
import { KanbanBoardPlanejadorFinanceiro } from '../KanbanPlanejadorFinanceiro';
import { useAdvisorPlanejadorFinanceiroContext } from '../../../Hooks/useAdvisorPlanejadorFinanceiroContext';

export const AdvisorPlanejadorFinanceiroKanban: React.FC = () => {
  const { loading, filteredKanbanList } = useAdvisorPlanejadorFinanceiroContext();

  if (loading) {
    return <GenericLoading height='50vh' />
  }

  return (
    <KanbanBoardPlanejadorFinanceiro
      isDraggable={false}
      loading={loading}
      filteredKanbanList={filteredKanbanList}
    />
  );
};
