import { KanbanBoardPlanejadorFinanceiro } from ".";
import { useKanbanNegociosPlanejadorFinanceiroContext } from "../../../Hooks/useKanbanNegociosPlanejadorFinanceiro";

export const KanbanToPlanejadorFinanceirosPage = () => {
  const {
    loading,
    onDragEnd,
    filteredKanbanList,
    users,
    setUsuarioId,
    usuarioId,
    isAdminPlanejadorFinanceiro,
    kanbanList,
    setKanbanList
  } = useKanbanNegociosPlanejadorFinanceiroContext();

  return <KanbanBoardPlanejadorFinanceiro
    isDraggable={true}
    loading={loading}
    onDragEnd={onDragEnd}
    filteredKanbanList={filteredKanbanList}
    users={users}
    setUsuarioId={setUsuarioId}
    usuarioId={usuarioId}
    isAdminPlanejadorFinanceiro={isAdminPlanejadorFinanceiro}
    showSearchInput={true}
    kanbanList={kanbanList}
    setKanbanList={setKanbanList}
  />
}