import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Paper,
  Chip,
  Tooltip,
  Divider
} from '@mui/material';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import XPInfosUploadsService from '../../../Services/XPInfosUploadsService';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { useUserInfosContext } from '../../../Contexts/UserInfos';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import { INPSUpload } from '../../../Interface/uploadsXPFiles';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export const XPInfosNPS = () => {
  const [data, setData] = useState<INPSUpload[]>([]);
  const [filteredData, setFilteredData] = useState<INPSUpload[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const storageHelper = new StorageHelper();
  const uploadsService = new XPInfosUploadsService();

  const { isAdmin, isSquadAdmin, userInfos, squadMembers } = useUserInfosContext();

  const defaultAssessorId = storageHelper.GetUsuarioId();
  const [selectedAdvisorId, setSelectedAdvisorId] = useState<string>(
    userInfos?.Id || defaultAssessorId
  );

  useEffect(() => {
    if (!selectedAdvisorId) return;

    const fetchData = async () => {
      setFilteredData([]);
      setData([]);
      setLoading(true);
      try {
        const response = await uploadsService.GetXPInfosNPSByAssessorId(selectedAdvisorId);

        setData(response);
        setFilteredData(response);
      } catch (error) {
        console.error('Erro ao buscar dados de NPS:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedAdvisorId]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredData(data);
      return;
    }

    const query = searchQuery.toLowerCase();
    const filtered = data.filter((item) =>
      JSON.stringify(item).toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  }, [data, searchQuery]);

  const npsScore = useMemo(() => {
    const detratores = filteredData.filter(
      (item) => item.Nota != null && item.Nota >= 0 && item.Nota <= 6
    ).length;
    const neutros = filteredData.filter(
      (item) => item.Nota === 7 || item.Nota === 8
    ).length;
    const promotores = filteredData.filter(
      (item) => item.Nota === 9 || item.Nota === 10
    ).length;

    const total = detratores + neutros + promotores;
    if (total === 0) return 0; // se não há respostas, NPS = 0 ou algo definido

    return (promotores - detratores) / total;
  }, [filteredData]);

  const npsIcon = useMemo(() => {
    const happyScore = 0.9;
    const sadScore = 0.8;

    if (npsScore >= happyScore) {
      return {
        icon: <SentimentVerySatisfiedIcon sx={{ color: '#5acea4', fontSize: 35 }} />,
        color: '#5acea4',
        label: 'Feliz'
      };
    } else if (npsScore >= sadScore && npsScore < happyScore) {
      return {
        icon: <SentimentNeutralIcon sx={{ color: '#ffbc00', fontSize: 35 }} />,
        color: '#ffbc00',
        label: 'Neutro'
      };
    } else {
      return {
        icon: <SentimentVeryDissatisfiedIcon sx={{ color: '#f44336', fontSize: 35 }} />,
        color: '#f44336',
        label: 'Triste'
      };
    }
  }, [npsScore]);


  const columns: GridColDef[] = [
    {
      field: 'SurveyStatus',
      headerName: 'Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Status</strong>,
      renderCell: (params) => {
        const status = params.value as string;
        if (status === 'COMPLETED') {
          return <Chip label='Concluído' color='success' />;
        } else if (status === 'DELIVERED') {
          return <Chip label='Entregue' sx={{ backgroundColor: '#ffbc00', color: '#000' }} />;
        } else if (status === 'DELIVERED_AND_REMINDED') {
          return <Chip label='Entregue e relembrado' sx={{ backgroundColor: '#ffbc00', color: '#000' }} />;
        } else {
          return status;
        }
      },
    },
    {
      field: 'DataEntrega',
      headerName: 'Data de envio',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Data de envio</strong>,
      renderCell: (params) => {
        const isoString = params.value as string;
        if (!isoString) return '';
        const dateObj = new Date(isoString);
        return dateObj.toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'NomeCliente',
      headerName: 'Cliente',
      flex: 1.5,
      renderHeader: () => <strong>Cliente</strong>,
      renderCell: (params) => {
        const row = params.row as INPSUpload;
        const clienteId = row.ClienteId;
        return (
          <a
            href={`/app/perfil-cliente/${clienteId}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#43425D', textDecoration: 'none', cursor: 'pointer', fontWeight: 'bold' }}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'CodigoCliente',
      headerName: 'Conta',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Conta</strong>,
    },
    // {
    //   field: 'NomeAssessor',
    //   headerName: 'Assessor',
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderHeader: () => <strong>Assessor</strong>,
    // },
    {
      field: 'DataResposta',
      headerName: 'Data da resposta',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Data da resposta</strong>,
      renderCell: (params) => {
        const isoString = params.value as string;
        if (!isoString) return '';
        const dateObj = new Date(isoString);
        return dateObj.toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'Nota',
      headerName: 'Nota',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Nota</strong>,
    },
    {
      field: 'Comentario',
      headerName: 'Mensagem',
      flex: 2,
      headerAlign: 'center',
      align: 'left',
      renderHeader: () => <strong>Mensagem</strong>,
      renderCell: (params) => {
        const text = String(params.value || '');

        return (
          <Tooltip title={text} placement='top' arrow>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 15,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 'max-content',
              }}
            >
              {text}
            </Typography>
          </Tooltip>
        );
      },
    },

  ];

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='60vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h3'>NPS - Informações</Typography>

        <Stack direction='row' spacing={4} alignItems='center'
          sx={{
            px: 3,
            backgroundColor: '#fff',
            borderRadius: 1,
            height: '60px',
            position: 'relative',
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <PersonIcon sx={{ color: '#bcbbce' }} />
            <Typography variant='h5'>{filteredData.length}</Typography>
          </Stack>

          <Divider orientation='vertical' flexItem />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {npsIcon.icon}
            <Box>
              <Typography variant='h6' sx={{ fontWeight: 'normal' }}>
                NPS
              </Typography>
              <Typography variant='h5' sx={{ color: npsIcon.color, fontWeight: 'bold' }}>
                {Math.round(npsScore * 100)}
              </Typography>
            </Box>
          </Box>

          {isSquadAdmin && !isAdmin && (
            <Box sx={{ my: 2 }}>
              <FormControl size='small' sx={{ minWidth: 200 }}>
                <InputLabel id='select-advisor-label'>Selecione um assessor</InputLabel>
                <Select
                  labelId='select-advisor-label'
                  id='select-advisor'
                  value={selectedAdvisorId}
                  label='Selecione um assessor'
                  onChange={(e) => setSelectedAdvisorId(e.target.value as string)}
                >
                  {squadMembers.map((advisor: any) => (
                    <MenuItem
                      key={advisor.Usuario.Id}
                      value={advisor.Usuario.Id}
                      sx={{ fontWeight: userInfos.Id === advisor.Usuario.Id ? 'bold' : 'normal' }}
                    >
                      {advisor.Usuario.NomeCompleto}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Stack>
      </Box>

      <Paper sx={{ p: 3, mb: 2, position: 'relative' }}>
        <Box sx={{ mb: 2 }}>
          <TextField
            label='Pesquisar'
            size='small'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <DataGrid
          sx={{
            fontSize: 15,
            border: 'none',
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid #efefef',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            minHeight: 500,
          }}
          rows={filteredData}
          columns={columns}
          checkboxSelection={false}
          autoHeight
          autoPageSize
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          getRowId={(row) => row.Id}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Paper>
    </Box>
  );
};
