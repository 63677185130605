import { INegocio, INegocioCambioRequest, INegocioConsorcioRequest, INegocioCreditoRequest, INegocioPlanejadorFinanceiroRequest, INegocioSeguroRequest, INegociosGroupResponse } from "../Interface/interfaceNegocios";
import { AUTH_HEADER } from "./Helpers/Headers";

export default class NegociosService {
  private readonly API_URL = process.env.REACT_APP_API_URL;

  private handleErrors(response: Response) {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  }

  public async CadastrarNegocioCambio(request: INegocioCambioRequest): Promise<INegocioCambioRequest> {
    return await fetch(`${this.API_URL}/negocios/cambios`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(request)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao cadastrar negócio de câmbio: ${err.message}`);
      });
  }

  public async CadastrarNegocioConsorcio(request: INegocioConsorcioRequest): Promise<INegocioConsorcioRequest> {
    return await fetch(`${this.API_URL}/negocios/consorcios`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(request)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao cadastrar negócio de câmbio: ${err.message}`);
      });
  }

  public async ListNegociosCambiosByVendedorId(vendedorId: string): Promise<INegocioCambioRequest[]> {
    return await fetch(`${this.API_URL}/negocios/cambios/list-by-vendedor/${vendedorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios pelo vendedorId: ${err.message}`);
      });
  }

  public async ListNegociosCambioByAssessorId(assessorId: string): Promise<INegocioCambioRequest[]> {
    return await fetch(`${this.API_URL}/negocios/cambios/by-assessor/${assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios pelo vendedorId: ${err.message}`);
      });
  }

  public async ListNegociosConsorciosByAssessorId(assessorId: string): Promise<INegocioConsorcioRequest[]> {
    return await fetch(`${this.API_URL}/negocios/consorcios/by-assessor/${assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios pelo vendedorId: ${err.message}`);
      });
  }

  public async ListNegociosConsorciosByVendedorId(vendedorId: string): Promise<INegocioConsorcioRequest[]> {
    return await fetch(`${this.API_URL}/negocios/consorcios/list-by-vendedor/${vendedorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios pelo vendedorId: ${err.message}`);
      });
  }

  public async UpdateNegocio(updates: Partial<INegocio>): Promise<boolean> {
    return await fetch(`${this.API_URL}/negocios/update?id=${updates.Id}`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(updates)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao atualizar negócio: ${err.message}`);
      });
  }

  public async ListNegociosByClienteId(clienteId: string): Promise<INegociosGroupResponse> {
    return await fetch(`${this.API_URL}/negocios/cliente/${clienteId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios pelo clienteId: ${err.message}`);
      });
  }

  public async UpdateNegocioCambio(id: string, data: Partial<INegocioCambioRequest["Cambio"]>): Promise<boolean> {
    return await fetch(`${this.API_URL}/negocios/cambios/${id}`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(data)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao atualizar negócio de câmbio: ${err.message}`);
      });
  }

  public async UpdateNegocioConsorcio(id: string, data: Partial<INegocioConsorcioRequest["Consorcio"]>): Promise<boolean> {
    return await fetch(`${this.API_URL}/negocios/consorcios/${id}`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(data)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao atualizar negócio de consórcio: ${err.message}`);
      });
  }

  public async CadastrarNegocioCredito(request: INegocioCreditoRequest): Promise<INegocioCreditoRequest> {
    return await fetch(`${this.API_URL}/negocios/creditos`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(request)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao cadastrar negócio de crédito: ${err.message}`);
      });
  }

  public async CadastrarNegocioSeguro(request: INegocioSeguroRequest): Promise<INegocioSeguroRequest> {
    return await fetch(`${this.API_URL}/negocios/seguros`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(request)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao cadastrar negócio de seguro: ${err.message}`);
      });
  }

  public async ListNegociosCreditosByVendedorId(vendedorId: string): Promise<INegocioCreditoRequest[]> {
    return await fetch(`${this.API_URL}/negocios/creditos/list-by-vendedor/${vendedorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios de crédito pelo vendedorId: ${err.message}`);
      });
  }

  public async ListNegociosCreditosByAssessorId(assessorId: string): Promise<INegocioCreditoRequest[]> {
    return await fetch(`${this.API_URL}/negocios/creditos/by-assessor/${assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios de crédito pelo assessorId: ${err.message}`);
      });
  }

  public async ListNegociosSeguroByVendedorId(vendedorId: string): Promise<INegocioSeguroRequest[]> {
    return await fetch(`${this.API_URL}/negocios/seguros/list-by-vendedor/${vendedorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios de seguro pelo vendedorId: ${err.message}`);
      });
  }

  public async ListNegociosSegurosByAssessorId(assessorId: string): Promise<INegocioSeguroRequest[]> {
    return await fetch(`${this.API_URL}/negocios/seguros/by-assessor/${assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios de seguro pelo assessorId: ${err.message}`);
      });
  }

  public async UpdateNegocioCredito(id: string, data: Partial<INegocioCreditoRequest["Credito"]>): Promise<boolean> {
    return await fetch(`${this.API_URL}/negocios/creditos/${id}`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(data)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao atualizar negócio de crédito: ${err.message}`);
      });
  }

  public async UpdateNegocioSeguro(id: string, data: Partial<INegocioSeguroRequest["Seguro"]>): Promise<boolean> {
    return await fetch(`${this.API_URL}/negocios/seguros/${id}`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(data)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao atualizar negócio de seguro: ${err.message}`);
      });
  }

  // Planejador Financeiro
  public async CadastrarNegocioPlanejadorFinanceiro(request: INegocioPlanejadorFinanceiroRequest): Promise<INegocioPlanejadorFinanceiroRequest> {
    return await fetch(`${this.API_URL}/negocios/planejadorfinanceiro`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(request)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao cadastrar negócio de Planejador Financeiro: ${err.message}`);
      });
  }

  public async UpdateNegocioPlanejadorFinanceiro(id: string, data: Partial<INegocioPlanejadorFinanceiroRequest["Planejador"]>): Promise<boolean> {
    return await fetch(`${this.API_URL}/negocios/planejadorfinanceiro/${id}`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(data)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao atualizar negócio de Planejador Financeiro: ${err.message}`);
      });
  }

  public async ListNegociosPlanejadorFinanceiroByVendedorId(vendedorId: string): Promise<INegocioPlanejadorFinanceiroRequest[]> {
    return await fetch(`${this.API_URL}/negocios/planejadorfinanceiro/list-by-vendedor/${vendedorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios de Planejador Financeiro pelo vendedorId: ${err.message}`);
      });
  }

  public async ListNegociosPlanejadorFinanceiroByAssessorId(assessorId: string): Promise<INegocioPlanejadorFinanceiroRequest[]> {
    return await fetch(`${this.API_URL}/negocios/planejadorfinanceiro/by-assessor/${assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(`Erro ao listar negócios de Planejador Financeiro pelo assessorId: ${err.message}`);
      });
  }


}
