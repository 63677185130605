import React, { useEffect, useState } from 'react';
import { TextField, Grid, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import StorageHelper from '../../../../../Services/Helpers/StorageHelper';
import UserService from '../../../../../Services/UserService';
import { INegocioPlanejadorFinanceiroRequest } from '../../../../../Interface/interfaceNegocios';

interface FormPlanejadorFinanceiroProps {
  initialData?: INegocioPlanejadorFinanceiroRequest;
  onSubmit: (data: INegocioPlanejadorFinanceiroRequest) => Promise<void>;
  onClose: () => void;
}

export const FormPlanejadorFinanceiro: React.FC<FormPlanejadorFinanceiroProps> = ({
  initialData,
  onSubmit,
  onClose,
}) => {
  const storageHelper = new StorageHelper();
  const userService = new UserService();
  const { userId: clientId } = useParams<{ userId: string }>();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<{ id: string; label: string }[]>([]);
  const [especialistaError, setEspecialistaError] = useState<boolean>(false);

  const [negocioPlanejador, setNegocioPlanejador] = useState<INegocioPlanejadorFinanceiroRequest>(
    initialData || {
      Negocio: {
        AssessorId: storageHelper.GetUsuarioId(),
        ClienteId: clientId,
        Status: 'LEAD',
        DataInicio: new Date(),
        DataFechamento: undefined,
        VendedorId: '',
        ReceitaBruta: 0,
        ReceitaEscritorio: 0,
        Expectativa: 'Alta',
        Descricao: '',
      },
      Planejador: {
        DescricaoInicio: '',
        Resultado: '',
      },
    }
  );

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userService.ListUsersByPermissions(['PlanejadorFinanceiro', 'AdminPlanejadorFinanceiro']);
      const formattedUsers = fetchedUsers.map((user) => ({
        id: user.Id,
        label: user.NomeCompleto,
      }));
      setUsers(formattedUsers);
      if (formattedUsers.length === 1) {
        setNegocioPlanejador((prevState) => ({
          ...prevState,
          Negocio: {
            ...prevState.Negocio,
            VendedorId: formattedUsers[0].id,
          },
        }));
      }
    } catch (error) {
      enqueueSnackbar('Erro ao buscar usuários.', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!negocioPlanejador.Negocio.VendedorId) {
      setEspecialistaError(true);
      enqueueSnackbar('Selecione o especialista!', { variant: 'error' });
      return;
    }
    setLoading(true);
    try {
      await onSubmit(negocioPlanejador);
      onClose();
    } catch (err: any) {
      enqueueSnackbar('Erro ao salvar o negócio de Planejador Financeiro.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems='center'>
        {/* Campo para selecionar o Especialista */}
        <Grid item xs={12} textAlign='center' sx={{ mb: 1 }}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setNegocioPlanejador((prevState) => ({
                ...prevState,
                Negocio: {
                  ...prevState.Negocio,
                  VendedorId: newValue ? newValue.id : '',
                },
              }));
              setEspecialistaError(false);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: 300 }}
                {...params}
                label='Especialista'
                variant='outlined'
                size='small'
                error={especialistaError}
                helperText={especialistaError ? 'Campo obrigatório' : ''}
              />
            )}
            value={users.find((user) => user.id === negocioPlanejador.Negocio.VendedorId) || null}
            noOptionsText='Nenhum usuário encontrado'
          />
        </Grid>

        {/* Campo para a Descrição */}
        <Grid item xs={12}>
          <TextField
            label='Descrição'
            name='DescricaoInicio'
            value={negocioPlanejador.Planejador.DescricaoInicio}
            onChange={(e) =>
              setNegocioPlanejador((prevState) => ({
                ...prevState,
                Planejador: {
                  ...prevState.Planejador,
                  DescricaoInicio: e.target.value,
                },
              }))
            }
            fullWidth
            multiline
            rows={4}
            variant='outlined'
          />
        </Grid>

        {/* Campo para Resultado: só exibe se for edição */}
        {initialData && (
          <Grid item xs={12}>
            <TextField
              label='Resultado'
              name='Resultado'
              value={negocioPlanejador.Planejador.Resultado}
              onChange={(e) =>
                setNegocioPlanejador((prevState) => ({
                  ...prevState,
                  Planejador: {
                    ...prevState.Planejador,
                    Resultado: e.target.value,
                  },
                }))
              }
              fullWidth
              multiline
              rows={4}
              variant='outlined'
            />
          </Grid>
        )}

        {/* Botão de Envio */}
        <Grid item xs={12}>
          <LoadingButton
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            loading={loading}
          >
            {initialData?.Negocio.Id
              ? 'Salvar Negócio'
              : 'Criar Negócio de Planejador Financeiro'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
