import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Box, Typography, Container, Autocomplete, TextField, Stack } from '@mui/material';
import { KanbanColumnCredito } from './KanbanColumn';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { KanbanSearchInputCredito } from './SearchInput';
import { IKanbanListCredito } from '../../../Hooks/useKanbanNegociosCredito';

interface KanbanBoardCreditosProps {
  isDraggable?: boolean;
  loading: boolean;
  onDragEnd?: (result: DropResult) => void;
  filteredKanbanList: any;
  users?: { id: string; label: string }[];
  setUsuarioId?: (id: string | null) => void;
  usuarioId?: string | null;
  isAdminCredito?: boolean;
  showSearchInput?: boolean;
  kanbanList?: IKanbanListCredito;
  setKanbanList?: React.Dispatch<React.SetStateAction<IKanbanListCredito>>;
}

export const KanbanBoardCreditos: React.FC<KanbanBoardCreditosProps> = ({
  isDraggable = true,
  loading,
  onDragEnd,
  filteredKanbanList,
  users,
  setUsuarioId,
  usuarioId,
  isAdminCredito,
  showSearchInput = false,
  kanbanList,
  setKanbanList
}) => {
  return (
    <Container maxWidth='xl'>
      <Box my={2} display='flex' flexDirection='column'>
        <Typography variant='h3' my={1}>Funil comercial de Crédito</Typography>
        <Stack alignItems='center' direction='row' spacing={6} width='max-content'
          sx={{
            px: 5,
            backgroundColor: '#fff',
            borderRadius: 1,
            height: '80px',
            position: 'relative',
          }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <WorkOutlineIcon sx={{ color: '#bcbbce' }} />
            <Typography variant='h5'>{Object.values(filteredKanbanList).reduce((acc: number, column: any) => acc + column.data.length, 0)}</Typography>
          </Stack>
          {(isAdminCredito && users && setUsuarioId) && (
            <Autocomplete
              size='small'
              options={users}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label='Selecione um usuário' variant='outlined' />
              )}
              value={users.find(user => user.id === usuarioId) || null}
              noOptionsText='Nenhuma opção disponível'
              onChange={(event, newValue) => {
                setUsuarioId(newValue ? newValue.id : null);
              }}
              sx={{ width: 230, mb: 2 }}
            />
          )}
        </Stack>
      </Box>
      <Box sx={{
        backgroundColor: '#ffffff',
        borderRadius: 1, width: 'max-content'
      }}>
        <Box
          sx={{
            pl: 3,
            pt: 3,
          }}
        >
          {showSearchInput && <KanbanSearchInputCredito />}
        </Box>
        <DragDropContext onDragEnd={isDraggable && onDragEnd ? onDragEnd : () => { }}>
          <Box display='flex' overflow='auto' p={2}>
            {Object.entries(filteredKanbanList).map(([status, column]) => {
              const typedColumn = column as { name: string; data: any[] };
              return (
                <KanbanColumnCredito
                  key={status}
                  status={status}
                  columnName={typedColumn.name}
                  negocios={typedColumn.data}
                  loading={loading}
                  isDraggable={isDraggable}
                  kanbanList={kanbanList}
                  setKanbanList={setKanbanList}
                />
              );
            })}
          </Box>
        </DragDropContext>
      </Box>
    </Container>
  );
};