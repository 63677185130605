import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { FormCambio } from './FormCambio';
import { FormConsorcio } from './FormConsorcio';
import { Divider, Typography } from '@mui/material';
import { useNegocios } from '../../../../../Hooks/useNegocios';
import { INegocioCambioRequest, INegocioConsorcioRequest, INegocioCreditoRequest, INegocioPlanejadorFinanceiroRequest, INegocioSeguroRequest } from '../../../../../Interface/interfaceNegocios';
import { FormCredito } from './FormCredito';
import { FormSeguro } from './FormSeguro';
import { FormPlanejadorFinanceiro } from './FormPlanejadorFinanceiro';

interface DialogNovoNegocioProps {
  open: boolean;
  onClose: () => void;
}

export const DialogNovoNegocioComponent: React.FC<DialogNovoNegocioProps> = ({ open, onClose }) => {
  const [tipoNegocio, setTipoNegocio] = useState<string>('cambio');
  const { createNegocio } = useNegocios();

  const handleTipoNegocioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipoNegocio(event.target.value);
  };

  const handleSubmitCambio = async (data: INegocioCambioRequest) => {
    try {
      await createNegocio(data);
      onClose();
    } catch (error: any) {
    }
  };

  const handleSubmitConsorcio = async (data: INegocioConsorcioRequest) => {
    try {
      await createNegocio(data);
      onClose();
    } catch (error: any) {
    }
  };


  const handleSubmitCredito = async (data: INegocioCreditoRequest) => {
    try {
      await createNegocio(data);
      onClose();
    } catch (error: any) { }
  };

  const handleSubmitSeguro = async (data: INegocioSeguroRequest) => {
    try {
      await createNegocio(data);
      onClose();
    } catch (error: any) { }
  };

  const handleSubmitPlanejadorFinanceiro = async (data: INegocioPlanejadorFinanceiroRequest) => {
    try {
      await createNegocio(data);
      onClose();
    } catch (error: any) { }
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle variant='h3'>Novo Negócio</DialogTitle>
      <DialogContent>
        <Divider sx={{ mb: 2, mt: 2 }} />
        <Typography variant='h4'>Tipo de Negócio</Typography>
        <FormControl component='fieldset' fullWidth>
          <RadioGroup row value={tipoNegocio}
            onChange={handleTipoNegocioChange}
            sx={{ justifyContent: 'center' }}
          >
            <FormControlLabel value='cambio' control={<Radio />} label='Câmbio' />
            <FormControlLabel value='consorcio' control={<Radio />} label='Consórcio' />
            <FormControlLabel value='credito' control={<Radio />} label='Crédito' />
            <FormControlLabel value='seguros' control={<Radio />} label='Seguros' />
            <FormControlLabel value='planejador-financeiro' control={<Radio />} label='Planejador Financeiro' />
            <FormControlLabel value='plano-de-saude' control={<Radio />} label='Plano de Saúde' disabled />
            <FormControlLabel value='pps' control={<Radio />} label='PPS' disabled />
          </RadioGroup>
        </FormControl>
        <Divider sx={{ mb: 4, mt: 2 }} />
        {tipoNegocio === 'cambio' && <FormCambio onClose={onClose} onSubmit={handleSubmitCambio} />}
        {tipoNegocio === 'consorcio' && <FormConsorcio onClose={onClose} onSubmit={handleSubmitConsorcio} />}
        {tipoNegocio === 'credito' && <FormCredito onClose={onClose} onSubmit={handleSubmitCredito} />}
        {tipoNegocio === 'seguros' && <FormSeguro onClose={onClose} onSubmit={handleSubmitSeguro} />}
        {tipoNegocio === 'planejador-financeiro' && <FormPlanejadorFinanceiro onClose={onClose} onSubmit={handleSubmitPlanejadorFinanceiro} />}
      </DialogContent>
    </Dialog>
  );
};
