import React, { useEffect, useState } from 'react';
import {
  List, ListItem, ListItemIcon, ListItemText, Divider,
  Collapse, ListItemButton, Button, IconButton, Avatar, Typography
} from '@mui/material';
import { Link, Navigate, useLocation } from 'react-router-dom';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { usePermissions } from '../../../Hooks/usePermissionsUser';
import {
  AiOutlineFunnelPlot, BiBarChartSquare, BiTask, MdPersonAddAlt
} from 'react-icons/all';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddCommentIcon from '@mui/icons-material/AddCommentOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DialogNovoLeadComponent } from './DialogNovoLeadComponent';
import LogoutIcon from '@mui/icons-material/Logout';
import { IPermissions } from '../../../Interface/interfacePermissions';
import ArrowRightIcon from '@mui/icons-material/ArrowRightOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

interface SideBarComponentProps {
  open: boolean;
}

interface Permission {
  PermissionName: IPermissions;
}

const ExternalLinkItem: React.FC<{ href: string; icon: JSX.Element; label: string; open: boolean }> = ({ href, icon, label, open }) => (
  <ListItemButton component='a' href={href} target='_blank' rel='noopener noreferrer' sx={{ pl: 2 }}>
    <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center' }}>
      {icon}
    </ListItemIcon>
    <ListItemText
      sx={{ opacity: open ? 1 : 0 }}
      primary={<Typography variant='h6' sx={{ color: '#f3f2ff', fontSize: '15px' }}>{label}</Typography>}
    />
  </ListItemButton>
);

const CollapseSection: React.FC<{ icon: JSX.Element; label: string; open: boolean; isOpen: boolean; toggleOpen: () => void }> = ({ icon, label, open, isOpen, toggleOpen, children }) => (
  <>
    <ListItemButton onClick={toggleOpen} sx={{ pl: 2 }}>
      <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center' }}>
        {icon}
      </ListItemIcon>
      <ListItemText
        sx={{ opacity: open ? 1 : 0 }}
        primary={<Typography variant='h6' sx={{ color: '#f3f2ff', fontSize: '15px' }}>{label}</Typography>}
      />
      {isOpen ? <ExpandLessIcon color='primary' /> : <ExpandMoreIcon color='primary' />}
    </ListItemButton>
    <Collapse in={isOpen} timeout='auto' unmountOnExit>
      {children}
    </Collapse>
  </>
);

export const SideBarComponentV2: React.FC<SideBarComponentProps> = ({ open }) => {
  const storageHelper = new StorageHelper();
  const [usuarioRole, setUsuarioRole] = useState<string>('');
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isFunilComerciaisOpen, setIsFunilComerciaisOpen] = useState(false);
  const [isFunilGerenciaisOpen, setIsFunilGerenciaisOpen] = useState(false);
  const [isXPInfosOpen, setIsXPInfosOpen] = useState(false);
  const [openDialogNewLead, setOpenDialogNewLead] = useState(false);
  const { permissions } = usePermissions();

  useEffect(() => {
    setUsuarioRole(storageHelper.GetUsuarioRole() || '');
  }, []);

  const toggleAdmin = () => setIsAdminOpen(!isAdminOpen);
  const toggleFunilComerciais = () => setIsFunilComerciaisOpen(!isFunilComerciaisOpen);
  const toggleFunilGerenciais = () => setIsFunilGerenciaisOpen(!isFunilGerenciaisOpen);
  const toggleXPInfos = () => setIsXPInfosOpen(!isXPInfosOpen);

  const Logout = () => {
    storageHelper.Logout();
    return <Navigate to='/' />;
  };

  // Função para verificar permissões
  const hasPermission = (roles: IPermissions[]): boolean =>
    roles.some(role => permissions.some((p: Permission) => p.PermissionName === role)) || usuarioRole === 'Administrador';

  const showFunilComercialSeguros = hasPermission(['Seguros', 'AdminSeguros']);
  const showFunilComercialCambio = hasPermission(['Câmbio', 'AdminCâmbio']);
  const showFunilComercialConsorcios = hasPermission(['Consórcio', 'AdminConsórcio']);
  const showFunilComercialCredito = hasPermission(['Crédito', 'AdminCrédito']);
  const showFunilComercialPlanejadorFinanceiro = hasPermission(['PlanejadorFinanceiro', 'AdminPlanejadorFinanceiro']);
  const hasTombamentoPermission = permissions.some((p: Permission) => p.PermissionName === 'Tombamento');

  const MenuItem: React.FC<{ to: string; icon: JSX.Element; label: string; open: boolean; onClick?: () => void }> = ({ to, icon, label, open, onClick }) => {
    const location = useLocation();

    const cleanPath = location.pathname.replace(/^\/app\//, '').replace(/\/$/, ''); // Remove prefixo e barra final
    const isSelected = cleanPath === to; // Verifica rota exata

    return (
      <ListItemButton
        sx={{
          pl: 2,
          ...(isSelected && {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            '& .MuiTypography-root': {
              color: '#ffffff',
            },
            borderLeft: '2px solid #ffffff3b', // Adicione uma borda para destacar
          })
        }}
        component={Link}
        to={to}
        onClick={onClick}
        selected={isSelected}
      >
        <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center' }}>
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={{ opacity: open ? 1 : 0 }}
          primary={
            <Typography
              variant='h6'
              sx={{ color: isSelected ? '#ffffff' : '#f3f2ff', fontSize: '15px' }}
            >
              {label}
            </Typography>
          }
        />
      </ListItemButton>
    );
  };

  return (
    <>
      <List sx={{ color: 'red' }}>
        <MenuItem to='dashboard-pbi' icon={<BiBarChartSquare style={{ height: 23, width: 23, color: '#a5a4bf' }} />} label='Dashboard Power BI' open={open} />
        <MenuItem to='atividades' icon={<InsertInvitationIcon style={{ height: 23, width: 23, color: '#a5a4bf' }} />} label='Atividades' open={open} />
        <MenuItem to='tasks-list' icon={<BiTask style={{ height: 23, width: 23, color: '#a5a4bf' }} />} label='Tarefas' open={open} />

        <Divider />

        <CollapseSection icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: '#a5a4bf' }} />} label='Funis Comerciais' open={open} isOpen={isFunilComerciaisOpen} toggleOpen={toggleFunilComerciais}>
          <MenuItem to='kanban' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Investimentos' open={open} />
          <MenuItem to='advisor-kanban' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Outros Negócios' open={open} />
        </CollapseSection>

        <CollapseSection icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: '#a5a4bf' }} />} label='Funis Gerenciais' open={open} isOpen={isFunilGerenciaisOpen} toggleOpen={toggleFunilGerenciais}>
          {showFunilComercialSeguros && <MenuItem to='kanban-insurance' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Seguros' open={open} />}
          {showFunilComercialSeguros && <MenuItem to='kanban-insurance-v2' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Seguros V2' open={open} />}
          {showFunilComercialCambio && <MenuItem to='kanban-cambio' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Câmbio' open={open} />}
          {showFunilComercialConsorcios && <MenuItem to='kanban-consorcios' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Consórcios' open={open} />}
          {showFunilComercialCredito && <MenuItem to='kanban-credito' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Crédito' open={open} />}
          {showFunilComercialPlanejadorFinanceiro && <MenuItem to='kanban-planejador-financeiro' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Planejador Financeiro' open={open} />}
        </CollapseSection>

        <MenuItem to='clientes' icon={<AccountBalanceWalletIcon style={{ height: 23, width: 23, color: '#a5a4bf' }} />} label='Clientes' open={open} />

        <Divider />

        <CollapseSection icon={<CurrencyExchangeIcon style={{ height: 23, width: 23, color: '#a5a4bf' }} />} label='Relacionamento' open={open} isOpen={isXPInfosOpen} toggleOpen={toggleXPInfos}>
          <MenuItem to='xp-infos/vencimentos' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Vencimentos' open={open} />
          <MenuItem to='xp-infos/saldo-em-conta' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='Saldo em Conta' open={open} />
          <MenuItem to='xp-infos/nps' icon={<ArrowRightIcon style={{ height: 23, width: 23, color: '#a5a4bf', opacity: 0.5 }} />} label='NPS' open={open} />
        </CollapseSection>

        <ExternalLinkItem href='https://app.pipefy.com/portals/32a605dd-a56b-4c55-bbed-c240baebd357' icon={<AddCommentIcon sx={{ color: '#a5a4bf' }} />} label='Solicitações' open={open} />

        {(usuarioRole === 'Administrador' || hasTombamentoPermission) && (
          <CollapseSection icon={<SupervisorAccountIcon sx={{ color: '#a5a4bf' }} />} label='Administração' open={open} isOpen={isAdminOpen} toggleOpen={toggleAdmin}>
            <MenuItem to='admin/powerbi' icon={<ArrowRightIcon style={{ height: 20, width: 20, color: '#a5a4bf', opacity: 0.5 }} />} label='Editar Dashboard PBI' open={open} />
            <MenuItem to='admin/squads' icon={<ArrowRightIcon fontSize='small' sx={{ color: '#a5a4bf', opacity: 0.5 }} />} label='Gestão de Squads' open={open} />
            <MenuItem to='admin/user-management' icon={<ArrowRightIcon fontSize='small' sx={{ color: '#a5a4bf', opacity: 0.5 }} />} label='Gestão de Usuários' open={open} />
            <MenuItem to='admin/upload-xp-files' icon={<ArrowRightIcon fontSize='small' sx={{ color: '#a5a4bf', opacity: 0.5 }} />} label='Upload XP' open={open} />
            <MenuItem to='admin/uploads-xp-files' icon={<ArrowRightIcon fontSize='small' sx={{ color: '#a5a4bf', opacity: 0.5 }} />} label='Upload XP Relatórios' open={open} />
            {hasTombamentoPermission && <MenuItem to='admin/reassign-clients' icon={<ArrowRightIcon fontSize='small' sx={{ color: '#a5a4bf', opacity: 0.5 }} />} label='Tombamento de Clientes' open={open} />}
          </CollapseSection>
        )}

        <MenuItem to='/' icon={<LogoutIcon sx={{ color: '#a5a4bf' }} />} label='Sair' open={open} onClick={Logout} />

        <ListItem sx={{ justifyContent: 'center' }}>
          {open ? (
            <Button onClick={() => setOpenDialogNewLead(true)} startIcon={<MdPersonAddAlt />} variant='contained' color='primary' sx={{ width: 150 }}>
              Novo Lead
            </Button>
          ) : (
            <Avatar variant='rounded' sx={{ bgcolor: '#E24BAC' }}>
              <IconButton onClick={() => setOpenDialogNewLead(true)}>
                <MdPersonAddAlt style={{ color: '#ffffff' }} />
              </IconButton>
            </Avatar>
          )}
        </ListItem>
      </List>

      <DialogNovoLeadComponent open={openDialogNewLead} onClose={() => setOpenDialogNewLead(false)} />
    </>
  );
};
