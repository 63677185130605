import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { createTask } from '../Components/Common/NewProfileClient/crudTask';
import { formattingDateToISO } from '../Components/Common/NewProfileClient/usefulFunctions';
import { interfaceSchedule } from '../Interface/intarfaceSchedule';
import { INegocioConsorcioRequest, INegocioCambioRequest, INegocioCreditoRequest, INegocioSeguroRequest, INegocioPlanejadorFinanceiroRequest } from '../Interface/interfaceNegocios';
import NegociosService from '../Services/NegociosService';
import StorageHelper from '../Services/Helpers/StorageHelper';
import { IPermissions } from '../Interface/interfacePermissions';

export const useMarkBusinessAsLost = () => {
  const [loading, setLoading] = useState(false);
  const negociosService = new NegociosService();
  const storageHelper = new StorageHelper();
  const vendedorId = storageHelper.GetUsuarioId();

  /**
   * Função para marcar o negócio como perdido (PERDEU).
   *
   * @param negocio - Objeto do negócio que será marcado como perdido.
   * @param reason - Motivo da perda.
   * @param kanbanList - Estado atual do Kanban.
   * @param setKanbanList - Função para atualizar o estado do Kanban.
   * @param area - Área pela qual o negócio foi criado (por exemplo, "Câmbio", "Consórcio", etc.).
   */
  const markBusinessAsLost = async (
    negocio: INegocioConsorcioRequest | INegocioCambioRequest | INegocioCreditoRequest | INegocioSeguroRequest | INegocioPlanejadorFinanceiroRequest,
    reason: string,
    kanbanList: any,
    setKanbanList: React.Dispatch<React.SetStateAction<any>>,
    area: IPermissions
  ) => {
    if (!negocio?.Negocio?.Id) return;

    setLoading(true);
    // Clona o estado atual para permitir reverter em caso de erro
    const prevKanbanList = JSON.parse(JSON.stringify(kanbanList));

    try {
      // Atualiza o status para "PERDEU" no backend
      await negociosService.UpdateNegocio({ Id: negocio.Negocio.Id, Status: "PERDEU" });

      // Remove o negócio da lista do Kanban
      const updatedKanban = { ...kanbanList };
      for (const key in updatedKanban) {
        if (updatedKanban[key].data.some((n: INegocioConsorcioRequest) => n.Negocio.Id === negocio.Negocio.Id)) {
          updatedKanban[key].data = updatedKanban[key].data.filter((n: INegocioConsorcioRequest) => n.Negocio.Id !== negocio.Negocio.Id);
          break;
        }
      }
      setKanbanList(updatedKanban);

      // Cria uma atividade para registrar a perda do negócio
      const now = new Date();
      const newActivity: interfaceSchedule = {
        DataEvento: formattingDateToISO(now),
        AtividadeFinalizada: true,
        Title: 'Negócio perdido',
        Descricao: `Negócio marcado como PERDEU em ${area}. Motivo: ${reason ?? 'Não informado'}.`,
        AssessorUsuarioId: vendedorId,
        ClienteId: negocio.Negocio.ClienteId!,
        Visto: false,
        DataTermino: formattingDateToISO(now),
        StatusTarefa: 'FINISHED',
        AtividadeFinalizadaData: formattingDateToISO(now),
        AtividadeTodoDia: false,
        CriadoPorArea: area
      };

      await createTask(newActivity);
      enqueueSnackbar("Negócio marcado como PERDEU com sucesso!", { variant: 'success' });
    } catch (error) {
      enqueueSnackbar("Erro ao marcar negócio como PERDEU.", { variant: 'error' });
      // Em caso de erro, reverte o estado do Kanban
      setKanbanList(prevKanbanList);
    } finally {
      setLoading(false);
    }
  };

  return { markBusinessAsLost, loading };
};
