import React, { useEffect, useRef, useState } from 'react';
import { useNegocios } from '../../../../Hooks/useNegocios';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Chip
} from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/EditOutlined';
import {
  INegocioCambioRequest,
  INegocioConsorcioRequest,
  INegocioCreditoRequest,
  INegocioSeguroRequest,
  INegocioPlanejadorFinanceiroRequest,
  INegociosGroupResponse
} from '../../../../Interface/interfaceNegocios';
import { formatEstrategiaConsorcio, formatStatusNegocios, truncateString } from '../../../../Helpers/formatters';
import { FormCambio } from '../Dialogs/NovoNegocio/FormCambio';
import { FormConsorcio } from '../Dialogs/NovoNegocio/FormConsorcio';
import { FormCredito } from '../Dialogs/NovoNegocio/FormCredito';
import { FormSeguro } from '../Dialogs/NovoNegocio/FormSeguro';
import { FormPlanejadorFinanceiro } from '../Dialogs/NovoNegocio/FormPlanejadorFinanceiro'; // <-- novo form

export const OthersNegocios: React.FC = () => {
  const {
    negocios,
    loading,
    fetchNegociosByClienteId,
    updateNegocioCambio,
    updateNegocioConsorcio,
    updateNegocioCredito,
    updateNegocioSeguro,
    updateNegocioPlanejadorFinanceiro
  } = useNegocios();
  const { userId: clientId } = useParams();

  const [selectedNegocio, setSelectedNegocio] = useState<
    | INegocioCambioRequest
    | INegocioConsorcioRequest
    | INegocioCreditoRequest
    | INegocioSeguroRequest
    | INegocioPlanejadorFinanceiroRequest
    | null
  >(null);
  const [openNegocioDetail, setOpenNegocioDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchNegociosByClienteId(clientId ?? '');
  }, [clientId]);

  const handleCardClick = (
    negocio:
      | INegocioCambioRequest
      | INegocioConsorcioRequest
      | INegocioCreditoRequest
      | INegocioSeguroRequest
      | INegocioPlanejadorFinanceiroRequest
  ) => {
    setSelectedNegocio(negocio);
    setOpenNegocioDetail(true);
    setIsEditing(false);
  };

  const handleCloseModal = () => {
    setOpenNegocioDetail(false);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const negociosGroup = negocios as INegociosGroupResponse;

  const totalVolumeCambio = negociosGroup?.NegociosCambio.reduce(
    (sum, negocio) => sum + (negocio.Cambio.ValorReais ?? 0),
    0
  );
  const totalVolumeConsorcio = negociosGroup?.NegociosConsorcio.reduce(
    (sum, negocio) => sum + (negocio.Consorcio.ValorFinanceiro ?? 0),
    0
  );
  const totalVolumeCredito = negociosGroup?.NegociosCredito.reduce(
    (sum, negocio) => sum + (negocio.Credito.Volume ?? 0),
    0
  );
  const totalPA = negociosGroup?.NegociosSeguro.reduce(
    (sum, negocio) => sum + (negocio.Seguro.PA ?? 0),
    0
  );

  const totalNegociosCambio = negociosGroup?.NegociosCambio.length || 0;
  const totalNegociosConsorcio = negociosGroup?.NegociosConsorcio.length || 0;
  const totalNegociosCredito = negociosGroup?.NegociosCredito.length || 0;
  const totalNegociosSeguro = negociosGroup?.NegociosSeguro.length || 0;
  const totalNegociosPlanejador = negociosGroup?.NegociosPlanejadorFinanceiro?.length || 0;

  const renderEmptyState = (message: string) => (
    <Box>
      <Typography variant='body1' align='center' sx={{ opacity: 0.5, my: 5, fontSize: 10 }}>
        {message}
      </Typography>
    </Box>
  );

  const renderNegocioPlanejadorFinanceiroCard = (negocio: INegocioPlanejadorFinanceiroRequest) => (
    <Card
      key={negocio.Negocio.Id}
      sx={{
        marginBottom: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
        },
      }}
      onClick={() => handleCardClick(negocio)}
    >
      <CardContent>
        <Box display='flex' alignItems='center'>
          <CurrencyExchangeIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
          <Typography variant='h6' color='primary'>
            {truncateString(negocio.Negocio.Descricao, 50)}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={1} mt={1} ml={1}>
          <Typography fontSize={12} color='gray'>
            Especialista: {negocio.NomeAssessor || '-'}
          </Typography>
          <Typography fontSize={12} color='gray'>
            Descrição: {negocio.Planejador.DescricaoInicio ? truncateString(negocio.Planejador.DescricaoInicio, 50) : '-'}
          </Typography>
          {negocio.Planejador.Resultado && (
            <Typography fontSize={12} color='gray'>
              Resultado: {truncateString(negocio.Planejador.Resultado, 50)}
            </Typography>
          )}
          <Typography fontSize={12} color='gray'>
            Status: {negocio.Negocio.Status ? formatStatusNegocios(negocio.Negocio.Status) : '-'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const renderNegocioCambioCard = (negocio: INegocioCambioRequest) => (
    <Card
      key={negocio.Negocio.Id}
      sx={{
        marginBottom: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
        },
      }}
      onClick={() => handleCardClick(negocio)}
    >
      <CardContent>
        <Box display='flex' alignItems='center'>
          <CurrencyExchangeIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
          <Typography variant='h6' color='primary'>{truncateString(negocio.Negocio.Descricao, 50)}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={1} mt={1} ml={1}>
          <Typography fontSize={12} color='gray'>
            Volume Operado: <strong style={{ color: 'green' }}>
              {negocio.Cambio.ValorReais ? negocio.Cambio.ValorReais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}
            </strong>
          </Typography>
          <Typography fontSize={12} color='gray'>Moeda: {negocio.Cambio.Moeda || '-'}</Typography>
          <Typography fontSize={12} color='gray'>País Parceiro: {negocio.Cambio.PaisParceiro || '-'}</Typography>
          <Typography fontSize={12} color='gray'>Status: {negocio.Negocio.Status ? formatStatusNegocios(negocio.Negocio.Status) : '-'}</Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const renderNegocioConsorcioCard = (negocio: INegocioConsorcioRequest) => (
    <Card
      key={negocio.Negocio.Id}
      sx={{
        marginBottom: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
        },
      }}
      onClick={() => handleCardClick(negocio)}
    >
      <CardContent>
        <Box display='flex' alignItems='center'>
          <CurrencyExchangeIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
          <Typography variant='h6' color='primary'>{truncateString(negocio.Negocio.Descricao, 50)}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={1} mt={1} ml={1}>
          <Typography fontSize={12} color='gray'>
            Valor Financeiro: <strong style={{ color: 'green' }}>
              {negocio.Consorcio.ValorFinanceiro ? negocio.Consorcio.ValorFinanceiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}
            </strong>
          </Typography>
          <Typography fontSize={12} color='gray'>Estratégia: {negocio.Consorcio.Estrategia ? formatEstrategiaConsorcio(negocio.Consorcio.Estrategia) : '-'}</Typography>
          <Typography fontSize={12} color='gray'>Tipo: {negocio.Consorcio.Tipo || '-'}</Typography>
          <Typography fontSize={12} color='gray'>Status: {negocio.Negocio.Status ? formatStatusNegocios(negocio.Negocio.Status) : '-'}</Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const renderNegocioCreditoCard = (negocio: INegocioCreditoRequest) => (
    <Card
      key={negocio.Negocio.Id}
      sx={{
        marginBottom: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
        },
      }}
      onClick={() => handleCardClick(negocio)}
    >
      <CardContent>
        <Box display='flex' alignItems='center'>
          <CurrencyExchangeIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
          <Typography variant='h6' color='primary'>{truncateString(negocio.Negocio.Descricao, 50)}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={1} mt={1} ml={1}>
          <Typography fontSize={12} color='gray'>
            Valor financeiro: <strong style={{ color: 'green' }}>
              {negocio.Credito.Volume ? negocio.Credito.Volume.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}
            </strong>
          </Typography>
          <Typography fontSize={12} color='gray'>Modalidade: {negocio.Credito.Modalidade || '-'}</Typography>
          <Typography fontSize={12} color='gray'>Status: {negocio.Negocio.Status ? formatStatusNegocios(negocio.Negocio.Status) : '-'}</Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const renderNegocioSeguroCard = (negocio: INegocioSeguroRequest) => (
    <Card
      key={negocio.Negocio.Id}
      sx={{
        marginBottom: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
        },
      }}
      onClick={() => handleCardClick(negocio)}
    >
      <CardContent>
        <Box display='flex' alignItems='center'>
          <CurrencyExchangeIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
          <Typography variant='h6' color='primary'>{truncateString(negocio.Negocio.Descricao, 50)}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={1} mt={1} ml={1}>
          <Typography fontSize={12} color='gray'>
            <strong>{negocio.Seguro.ConfiguracaoFamiliar || '-'}</strong>
          </Typography>
          {negocio.Seguro.PA && (
            <Typography fontSize={12} color='gray'>
              PA: <strong style={{ color: 'green' }}>
                {negocio.Seguro.PA.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </strong>
            </Typography>
          )}
          <Typography fontSize={12} color='gray'>
            Status: {negocio.Negocio.Status ? formatStatusNegocios(negocio.Negocio.Status) : '-'}
          </Typography>
          <Box display='flex' justifyContent='center' gap={1} my={1}>
            {negocio.Seguro.InteresseReuniaoSegurosDeVida && (
              <Tooltip title='Seguro de Vida' disableInteractive>
                <Chip label='SV' size='small' color='primary' />
              </Tooltip>
            )}
            {negocio.Seguro.InteresseReuniaoPlanoDeSaude && (
              <Tooltip title='Plano de Saúde' disableInteractive>
                <Chip label='PS' size='small' color='info' />
              </Tooltip>
            )}
            {negocio.Seguro.InteresseReuniaoPlanejamentoPatrimonial && (
              <Tooltip title='Planejamento Patrimonial' disableInteractive>
                <Chip label='PPS' size='small' color='secondary' />
              </Tooltip>
            )}
            {negocio.Seguro.InteresseReuniaoOutros && (
              <Tooltip title='Outros' disableInteractive>
                <Chip label='Outros' size='small' />
              </Tooltip>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', maxWidth: 1120, marginX: '15px' }}>
      {/* Botões de Navegação */}
      <IconButton
        onClick={scrollLeft}
        sx={{
          position: 'absolute',
          top: '30%',
          left: -40,
          transform: 'translateY(-50%)',
          zIndex: 1,
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <Box ref={scrollContainerRef} sx={{ display: 'flex', overflowX: 'auto' }}>
        <Grid container spacing={2} wrap='nowrap' sx={{ flexWrap: 'nowrap' }}>
          <Grid item minWidth={300} maxWidth={330}>
            <Typography variant='h5' align='center'>Câmbio</Typography>
            <Typography variant='subtitle1' align='center' fontSize={10} sx={{ color: 'gray', mb: 2 }}>
              {totalVolumeCambio?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {totalNegociosCambio} negócio(s)
            </Typography>
            {totalNegociosCambio > 0
              ? negociosGroup.NegociosCambio.map(renderNegocioCambioCard)
              : renderEmptyState('Nenhum negócio de câmbio foi criado')}
          </Grid>

          <Grid item minWidth={300} maxWidth={330}>
            <Typography variant='h5' align='center'>Consórcio</Typography>
            <Typography variant='subtitle1' align='center' fontSize={10} sx={{ color: 'gray', mb: 2 }}>
              {totalVolumeConsorcio?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {totalNegociosConsorcio} negócio(s)
            </Typography>
            {totalNegociosConsorcio > 0
              ? negociosGroup.NegociosConsorcio.map(renderNegocioConsorcioCard)
              : renderEmptyState('Nenhum negócio de consórcio foi criado')}
          </Grid>

          <Grid item minWidth={300} maxWidth={330}>
            <Typography variant='h5' align='center'>Crédito</Typography>
            <Typography variant='subtitle1' align='center' fontSize={10} sx={{ color: 'gray', mb: 2 }}>
              {totalVolumeCredito?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {totalNegociosCredito} negócio(s)
            </Typography>
            {totalNegociosCredito > 0
              ? negociosGroup.NegociosCredito.map(renderNegocioCreditoCard)
              : renderEmptyState('Nenhum negócio de crédito foi criado')}
          </Grid>

          <Grid item minWidth={300} maxWidth={330}>
            <Typography variant='h5' align='center'>Seguros</Typography>
            <Typography variant='subtitle1' align='center' fontSize={10} sx={{ color: 'gray', mb: 2 }}>
              {totalPA?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {totalNegociosSeguro} negócio(s)
            </Typography>
            {totalNegociosSeguro > 0
              ? negociosGroup.NegociosSeguro.map(renderNegocioSeguroCard)
              : renderEmptyState('Nenhum negócio de seguro foi criado')}
          </Grid>

          <Grid item minWidth={300} maxWidth={330}>
            <Typography variant='h5' align='center'>Planejador Financeiro</Typography>
            <Typography variant='subtitle1' align='center' fontSize={10} sx={{ color: 'gray', mb: 2 }}>
              {totalNegociosPlanejador} negócio(s)
            </Typography>
            {totalNegociosPlanejador > 0
              ? negociosGroup.NegociosPlanejadorFinanceiro.map(renderNegocioPlanejadorFinanceiroCard)
              : renderEmptyState('Nenhum negócio de Planejador Financeiro foi criado')}
          </Grid>
        </Grid>

        <Dialog open={!!selectedNegocio && openNegocioDetail} onClose={handleCloseModal} maxWidth='sm' fullWidth>
          <DialogTitle variant='h4'>
            {isEditing ? 'Editar Negócio' : 'Detalhes do Negócio'}
            <IconButton
              aria-label='close'
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {!isEditing && (
              <IconButton
                aria-label='edit'
                onClick={handleEditClick}
                sx={{
                  position: 'absolute',
                  right: 48,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </DialogTitle>
          {selectedNegocio && (
            <DialogContent dividers>
              {isEditing ? (
                'Cambio' in selectedNegocio ? (
                  <FormCambio
                    initialData={selectedNegocio}
                    onSubmit={async (data) => {
                      await updateNegocioCambio(data);
                      setIsEditing(false);
                      setSelectedNegocio(data);
                    }}
                    onClose={() => setIsEditing(false)}
                  />
                ) : 'Consorcio' in selectedNegocio ? (
                  <FormConsorcio
                    initialData={selectedNegocio}
                    onSubmit={async (data) => {
                      await updateNegocioConsorcio(data);
                      setIsEditing(false);
                      setSelectedNegocio(data);
                    }}
                    onClose={() => setIsEditing(false)}
                  />
                ) : 'Credito' in selectedNegocio ? (
                  <FormCredito
                    initialData={selectedNegocio}
                    onSubmit={async (data) => {
                      await updateNegocioCredito(data);
                      setIsEditing(false);
                      setSelectedNegocio(data);
                    }}
                    onClose={() => setIsEditing(false)}
                  />
                ) : 'Seguro' in selectedNegocio ? (
                  <FormSeguro
                    initialData={selectedNegocio}
                    onSubmit={async (data) => {
                      await updateNegocioSeguro(data);
                      setIsEditing(false);
                      setSelectedNegocio(data);
                    }}
                    onClose={() => setIsEditing(false)}
                  />
                ) : 'Planejador' in selectedNegocio ? (
                  <FormPlanejadorFinanceiro
                    initialData={selectedNegocio as INegocioPlanejadorFinanceiroRequest}
                    onSubmit={async (data) => {
                      await updateNegocioPlanejadorFinanceiro(data);
                      setIsEditing(false);
                      setSelectedNegocio(data);
                    }}
                    onClose={() => setIsEditing(false)}
                  />
                ) : null
              ) : (
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                  {'Cambio' in selectedNegocio && (
                    <>
                      <Typography><strong>Descrição:</strong> {selectedNegocio.Negocio.Descricao}</Typography>
                      <Typography><strong>Expectativa:</strong> {selectedNegocio.Negocio.Expectativa}</Typography>
                      <Typography><strong>Volume Operado:</strong> {selectedNegocio.Cambio.ValorReais ? selectedNegocio.Cambio.ValorReais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</Typography>
                      <Typography><strong>Moeda:</strong> {selectedNegocio.Cambio.Moeda}</Typography>
                      <Typography><strong>País Parceiro:</strong> {selectedNegocio.Cambio.PaisParceiro}</Typography>
                      <Typography><strong>Pessoa Física:</strong> {selectedNegocio.Cambio.PessoaFisica ? 'Sim' : 'Não'}</Typography>
                      <Typography><strong>Saída Fiscal:</strong> {selectedNegocio.Cambio.SaidaFiscal ? 'Sim' : 'Não'}</Typography>
                    </>
                  )}
                  {'Consorcio' in selectedNegocio && (
                    <>
                      <Typography><strong>Descrição:</strong> {selectedNegocio.Negocio.Descricao}</Typography>
                      <Typography><strong>Expectativa:</strong> {selectedNegocio.Negocio.Expectativa}</Typography>
                      <Typography><strong>Estratégia:</strong> {selectedNegocio.Consorcio.Estrategia ? formatEstrategiaConsorcio(selectedNegocio.Consorcio.Estrategia) : '-'}</Typography>
                      <Typography><strong>Tipo:</strong> {selectedNegocio.Consorcio.Tipo}</Typography>
                      <Typography><strong>Valor Financeiro:</strong> {selectedNegocio.Consorcio.ValorFinanceiro?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                    </>
                  )}
                  {'Credito' in selectedNegocio && (
                    <>
                      <Typography><strong>Descrição:</strong> {selectedNegocio.Negocio.Descricao}</Typography>
                      <Typography><strong>Expectativa:</strong> {selectedNegocio.Negocio.Expectativa}</Typography>
                      <Typography><strong>Volume:</strong> {selectedNegocio.Credito.Volume?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                      <Typography><strong>Modalidade:</strong> {selectedNegocio.Credito.Modalidade}</Typography>
                      <Typography><strong>Pessoa Física:</strong> {selectedNegocio.Credito.PessoaFisica ? 'Sim' : 'Não'}</Typography>
                    </>
                  )}
                  {'Seguro' in selectedNegocio && (
                    <>
                      <Typography><strong>Configuração Familiar:</strong> {selectedNegocio.Seguro.ConfiguracaoFamiliar || '-'}</Typography>
                      <Typography><strong>Expectativa:</strong> {selectedNegocio.Negocio.Expectativa}</Typography>
                      <Typography>
                        <strong>PA:</strong> {selectedNegocio.Seguro.PA
                          ? selectedNegocio.Seguro.PA.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                          : '-'}
                      </Typography>
                      <Typography>
                        <strong>Forma de Pagamento:</strong> {selectedNegocio.Seguro.FormaDePagamento || '-'}
                      </Typography>
                      <Typography><strong>Possui Seguros:</strong> {selectedNegocio.Seguro.PossuiSeguros === null ? '-' : (selectedNegocio.Seguro.PossuiSeguros ? 'Sim' : 'Não')}</Typography>
                    </>
                  )}
                  {'Planejador' in selectedNegocio && (
                    <>
                      {/* <Typography><strong>Descrição:</strong> {selectedNegocio.Negocio.Descricao}</Typography> */}
                      <Typography><strong>Expectativa:</strong> {selectedNegocio.Negocio.Expectativa}</Typography>
                      <Typography><strong>Especialista:</strong> {selectedNegocio.NomeAssessor || '-'}</Typography>
                      <Typography><strong>Descrição do Negócio:</strong> {selectedNegocio.Planejador.DescricaoInicio || '-'}</Typography>
                      {selectedNegocio.Planejador.Resultado && (
                        <Typography><strong>Resultado:</strong> {selectedNegocio.Planejador.Resultado}</Typography>
                      )}
                    </>
                  )}
                  <Typography><strong>Data Início:</strong> {new Date(selectedNegocio.Negocio.DataInicio).toLocaleDateString()}</Typography>
                  <Typography><strong>Data Fechamento:</strong> {selectedNegocio.Negocio.DataFechamento ? new Date(selectedNegocio.Negocio.DataFechamento).toLocaleDateString() : '-'}</Typography>
                </Box>
              )}
            </DialogContent>
          )}
        </Dialog>
      </Box>

      <IconButton
        onClick={scrollRight}
        sx={{
          position: 'absolute',
          top: '30%',
          right: -40,
          transform: 'translateY(-50%)',
          zIndex: 1,
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};
