import { KanbanBoardConsorcios } from ".";
import { useKanbanNegociosConsorciosContext } from "../../../Hooks/useKanbanNegociosConsorcio";

export const KanbanToConsorciosPage = () => {
  const {
    loading,
    onDragEnd,
    filteredKanbanList,
    users,
    setUsuarioId,
    usuarioId,
    isAdminConsorcio,
    kanbanList,
    setKanbanList
  } = useKanbanNegociosConsorciosContext();

  return <KanbanBoardConsorcios
    isDraggable={true}
    loading={loading}
    onDragEnd={onDragEnd}
    filteredKanbanList={filteredKanbanList}
    users={users}
    setUsuarioId={setUsuarioId}
    usuarioId={usuarioId}
    isAdminConsorcio={isAdminConsorcio}
    showSearchInput={true}
    kanbanList={kanbanList}
    setKanbanList={setKanbanList}
  />
}