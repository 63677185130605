import { useKanbanNegociosCambioContext } from "../../../Hooks/useKanbanNegociosCambio"
import { KanbanBoardCambio } from "."

export const KanbanToCambioPage = () => {
  const {
    loading,
    onDragEnd,
    filteredKanbanList,
    users,
    setUsuarioId,
    usuarioId,
    isAdminCambio,
    kanbanList,
    setKanbanList
  } = useKanbanNegociosCambioContext();

  return <KanbanBoardCambio
    isDraggable={true}
    loading={loading}
    onDragEnd={onDragEnd}
    filteredKanbanList={filteredKanbanList}
    users={users}
    setUsuarioId={setUsuarioId}
    usuarioId={usuarioId}
    isAdminCambio={isAdminCambio}
    showSearchInput={true}
    kanbanList={kanbanList}
    setKanbanList={setKanbanList}
  />
}