import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from "react";
import NegociosService from "../Services/NegociosService";
import UserService from "../Services/UserService";
import { enqueueSnackbar } from "notistack";
import { DropResult } from "react-beautiful-dnd";
import StorageHelper from '../Services/Helpers/StorageHelper';
import { INegocio, INegocioPlanejadorFinanceiroRequest } from "../Interface/interfaceNegocios";
import { usePermissions } from "./usePermissionsUser";
import { createTask } from "../Components/Common/NewProfileClient/crudTask";
import { formattingDateToISO } from "../Components/Common/NewProfileClient/usefulFunctions";
import { interfaceSchedule } from "../Interface/intarfaceSchedule";

export type CategoriaKanbanPlanejadorFinanceiro = 'lead' | 'reuniaoMarcada' | 'r1' | 'r2' | 'planejamentoConcluido';

const statusMapping: Record<CategoriaKanbanPlanejadorFinanceiro, string> = {
  lead: "LEAD",
  reuniaoMarcada: "REUNIAO_MARCADA",
  r1: "R1",
  r2: "R2",
  planejamentoConcluido: "PLANEJAMENTO_CONCLUIDO",
};

const droppableIdToName: Record<CategoriaKanbanPlanejadorFinanceiro, string> = {
  lead: 'Lead',
  reuniaoMarcada: "Reunião Marcada",
  r1: 'R1',
  r2: 'R2',
  planejamentoConcluido: 'Planejamento Concluído',
};

export interface IKanbanListPlanejadorFinanceiro {
  lead: {
    name: string;
    data: INegocioPlanejadorFinanceiroRequest[];
  };
  reuniaoMarcada: {
    name: string;
    data: INegocioPlanejadorFinanceiroRequest[];
  };
  r1: {
    name: string;
    data: INegocioPlanejadorFinanceiroRequest[];
  };
  r2: {
    name: string;
    data: INegocioPlanejadorFinanceiroRequest[];
  };
  planejamentoConcluido: {
    name: string;
    data: INegocioPlanejadorFinanceiroRequest[];
  };
}

const useKanbanNegociosPlanejadorFinanceiro = () => {
  const negociosService = new NegociosService();
  const userService = new UserService();
  const storageHelper = new StorageHelper();
  const vendedorId = storageHelper.GetUsuarioId();
  const { permissions } = usePermissions();

  const [kanbanList, setKanbanList] = useState<IKanbanListPlanejadorFinanceiro>({
    lead: { name: 'Lead', data: [] },
    reuniaoMarcada: { name: 'Reunião Marcada', data: [] },
    r1: { name: 'R1', data: [] },
    r2: { name: 'R2', data: [] },
    planejamentoConcluido: { name: 'Planejamento Concluído', data: [] },
  });

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [usuarioId, setUsuarioId] = useState<string | null>(null);
  const [users, setUsers] = useState<{ id: string, label: string }[]>([]);

  const isAdminPlanejadorFinanceiro = useMemo(() => {
    return permissions.some(permission => (permission.PermissionName === 'AdminPlanejadorFinanceiro')) || storageHelper.GetUsuarioRole() === 'Administrador';
  }, [permissions]);

  const fetchUsersByPermission = async () => {
    if (isAdminPlanejadorFinanceiro) {
      try {
        const fetchedUsers = await userService.ListUsersByPermissions(['PlanejadorFinanceiro', 'AdminPlanejadorFinanceiro']);
        const formattedUsers = fetchedUsers.map(user => ({
          id: user.Id,
          label: user.NomeCompleto,
        }));

        setUsers([...formattedUsers]);
      } catch (error) {
        enqueueSnackbar('Erro ao buscar usuários.', { variant: 'error' });
      }
    }
  };

  const fetchNegocios = async () => {
    setLoading(true);
    try {
      const negocios = await negociosService.ListNegociosPlanejadorFinanceiroByVendedorId(usuarioId || vendedorId);

      const kanban: IKanbanListPlanejadorFinanceiro = {
        lead: { name: 'Lead', data: [] },
        reuniaoMarcada: { name: 'Reunião Marcada', data: [] },
        r1: { name: 'R1', data: [] },
        r2: { name: 'R2', data: [] },
        planejamentoConcluido: { name: 'Planejamento Concluído', data: [] },
      };

      negocios.forEach(negocio => {
        const statusKey = Object.keys(statusMapping).find(key => statusMapping[key as keyof typeof statusMapping] === negocio.Negocio.Status);
        if (statusKey) {
          kanban[statusKey as keyof typeof statusMapping].data.push(negocio);
        }
      });

      setKanbanList(kanban);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar negócios.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNegocios();
  }, [usuarioId]);

  useEffect(() => {
    fetchUsersByPermission();
  }, [isAdminPlanejadorFinanceiro]);

  const filteredKanbanList = useMemo(() => {
    if (!searchTerm) {
      return Object.fromEntries(
        Object.entries(kanbanList).map(([key, column]) => [
          key,
          {
            ...column,
            data: column.data.sort((a: INegocioPlanejadorFinanceiroRequest, b: INegocioPlanejadorFinanceiroRequest) => new Date(b.Negocio.DataInicio).getTime() - new Date(a.Negocio.DataInicio).getTime())
          }
        ])
      ) as IKanbanListPlanejadorFinanceiro;
    }

    const lowercasedSearchTerm = searchTerm.toLowerCase();

    const filterData = (negocios: INegocioPlanejadorFinanceiroRequest[]) =>
      negocios
        .filter(negocio =>
          negocio.NomeCliente?.toLowerCase().includes(lowercasedSearchTerm)
        )
        .sort((a, b) => new Date(b.Negocio.DataInicio).getTime() - new Date(a.Negocio.DataInicio).getTime());

    return Object.fromEntries(
      Object.entries(kanbanList).map(([key, column]) => [
        key,
        {
          ...column,
          data: filterData(column.data)
        }
      ])
    ) as IKanbanListPlanejadorFinanceiro;
  }, [searchTerm, kanbanList]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || destination.droppableId === source.droppableId) return;

    const startColumnData = kanbanList[source.droppableId as keyof typeof statusMapping].data;
    const finishColumnData = kanbanList[destination.droppableId as keyof typeof statusMapping].data;

    const negocioMoved = startColumnData.find(negocio => negocio.Negocio.Id === draggableId);
    if (!negocioMoved) return;

    if (negocioMoved.Negocio.VendedorId !== vendedorId) {
      enqueueSnackbar('Você não tem permissão para mover este negócio.', { variant: 'error' });
      return;
    }

    const newStatus = statusMapping[destination.droppableId as keyof typeof statusMapping];

    const prevKanbanList = { ...kanbanList };

    setKanbanList(prevState => {
      const newStartColumnData = startColumnData.filter(negocio => negocio.Negocio.Id !== draggableId);
      const updatedNegocioMoved = { ...negocioMoved, Negocio: { ...negocioMoved.Negocio, Status: newStatus } };
      const newFinishColumnData = [updatedNegocioMoved, ...finishColumnData];

      return {
        ...prevState,
        [source.droppableId]: {
          ...prevState[source.droppableId as keyof typeof statusMapping],
          data: newStartColumnData,
        },
        [destination.droppableId]: {
          ...prevState[destination.droppableId as keyof typeof statusMapping],
          data: newFinishColumnData,
        }
      };
    });

    try {
      const updateData: Partial<INegocio> = {
        Id: negocioMoved.Negocio.Id,
        Status: newStatus as INegocio['Status']
      };

      if (destination.droppableId === 'planejamentoConcluido') {
        updateData.DataFechamento = new Date();
      }

      await negociosService.UpdateNegocio(updateData);

      const now = new Date();
      const descricao = `Movido de ${droppableIdToName[source.droppableId as CategoriaKanbanPlanejadorFinanceiro]} para ${droppableIdToName[destination.droppableId as CategoriaKanbanPlanejadorFinanceiro]}.`;

      const newActivity: interfaceSchedule = {
        DataEvento: formattingDateToISO(now),
        AtividadeFinalizada: true,
        Title: 'Movimentação em Planejador Financeiro',
        Descricao: descricao,
        AssessorUsuarioId: vendedorId,
        ClienteId: negocioMoved.Negocio.ClienteId!,
        Visto: false,
        DataTermino: formattingDateToISO(now),
        StatusTarefa: 'FINISHED',
        AtividadeFinalizadaData: formattingDateToISO(now),
        AtividadeTodoDia: false,
        CriadoPorArea: 'PlanejadorFinanceiro'
      };

      await createTask(newActivity);
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar status.', { variant: 'error' });

      setKanbanList(prevKanbanList);
    }
  };

  return {
    kanbanList,
    onDragEnd,
    setKanbanList,
    loading,
    fetchNegocios,
    filteredKanbanList,
    setSearchTerm,
    users,
    setUsuarioId,
    usuarioId,
    isAdminPlanejadorFinanceiro
  };
};

interface KanbanNegociosPlanejadorFinanceiroContextProps {
  kanbanList: IKanbanListPlanejadorFinanceiro;
  setKanbanList: React.Dispatch<React.SetStateAction<IKanbanListPlanejadorFinanceiro>>;
  onDragEnd: (result: DropResult) => Promise<void>;
  loading: boolean;
  fetchNegocios: () => Promise<void>;
  filteredKanbanList: IKanbanListPlanejadorFinanceiro;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  users: { id: string, label: string }[];
  setUsuarioId: React.Dispatch<React.SetStateAction<string | null>>;
  usuarioId: string | null;
  isAdminPlanejadorFinanceiro: boolean;
}

const KanbanNegociosPlanejadorFinanceiroContext = createContext<KanbanNegociosPlanejadorFinanceiroContextProps | undefined>(undefined);

export const KanbanNegociosPlanejadorFinanceiroProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const kanbanNegociosPlanejadorFinanceiro = useKanbanNegociosPlanejadorFinanceiro();

  return (
    <KanbanNegociosPlanejadorFinanceiroContext.Provider value={kanbanNegociosPlanejadorFinanceiro}>
      {children}
    </KanbanNegociosPlanejadorFinanceiroContext.Provider>
  );
};

export const useKanbanNegociosPlanejadorFinanceiroContext = () => {
  const context = useContext(KanbanNegociosPlanejadorFinanceiroContext);
  if (!context) {
    throw new Error('useKanbanNegociosPlanejadorFinanceiroContext must be used within a KanbanNegociosPlanejadorFinanceiroProvider');
  }
  return context;
};
