import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography, Chip, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { INegocioSeguroRequest } from '../../../Interface/interfaceNegocios';
import { formatCurrencyBRL } from '../../../Helpers/formatters';
import { IKanbanListSeguro, useKanbanNegociosSegurosContext } from '../../../Hooks/useKanbanNegociosSeguro';
import { useChangeStatusWithReason } from '../../../Hooks/useChangeStatusWithReason';
import { LoseDialog } from '../KanbanCambio/LoseDialog';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { useMarkBusinessAsLost } from '../../../Hooks/useMarkBusinessAsLost';

interface Props {
  negocio: INegocioSeguroRequest;
  index: number;
  kanbanList?: IKanbanListSeguro;
  setKanbanList?: React.Dispatch<React.SetStateAction<IKanbanListSeguro>>;
}

export const KanbanItemSeguros: React.FC<Props> = ({ negocio, index, kanbanList, setKanbanList }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { changeStatusWithReason } = useChangeStatusWithReason();
  const [openDialog, setOpenDialog] = useState(false);
  const storageHelper = new StorageHelper();

  const { markBusinessAsLost } = useMarkBusinessAsLost();

  const usuarioId = storageHelper.GetUsuarioId();

  const draggableId = negocio.Negocio.Id ?? `negocio-${index}`;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    const url = `/app/perfil-cliente/${negocio.Negocio.ClienteId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
    handleClose();
  };

  const handleOpenLoseDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleConfirmLose = async (reason: string) => {
    if (kanbanList && setKanbanList) {
      await markBusinessAsLost(negocio, reason, kanbanList, setKanbanList, 'Seguros');
    }
  };

  return (
    <>
      <Draggable draggableId={draggableId} index={index}>
        {(provided) => (
          <Grid item xs={12} mx={2}>
            <Card
              elevation={2}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{ position: 'relative' }}
            >
              <CardContent>
                <Box display='flex' alignItems='center' mb={1}>
                  <PersonIcon style={{ marginRight: 8 }} />
                  <Typography variant='h6' fontSize={14}>{negocio.NomeCliente || '-'}</Typography>
                </Box>
                <Typography variant='body1' fontSize={13} fontWeight='bold' sx={{ my: 1 }}>
                  {negocio.NomeVendedor ? negocio.NomeVendedor : (negocio.NomeAssessor ?? '-')}
                </Typography>

                {negocio.Seguro.PA && (
                  <Box display='flex' justifyContent='center' mb={1}>
                    <Tooltip title='PA' disableInteractive>
                      <Typography variant='body1' fontSize={13} fontWeight='bold'>
                        {formatCurrencyBRL(negocio.Seguro.PA)}
                      </Typography>
                    </Tooltip>
                  </Box>
                )}

                <Box display='flex' justifyContent='center' gap={1} my={1}>
                  {negocio.Seguro.InteresseReuniaoSegurosDeVida && (
                    <Tooltip title='Seguro de Vida' disableInteractive>
                      <Chip label='SV' size='small' color='primary' />
                    </Tooltip>
                  )}
                  {negocio.Seguro.InteresseReuniaoPlanoDeSaude && (
                    <Tooltip title='Plano de Saúde' disableInteractive>
                      <Chip label='PS' size='small' color='info' />
                    </Tooltip>
                  )}
                  {negocio.Seguro.InteresseReuniaoPlanejamentoPatrimonial && (
                    <Tooltip title='Planejamento Patrimonial' disableInteractive>
                      <Chip label='PPS' size='small' color='secondary' />
                    </Tooltip>
                  )}
                  {negocio.Seguro.InteresseReuniaoOutros && (
                    <Chip label='Outros' size='small' />
                  )}
                </Box>
              </CardContent>

              <IconButton
                sx={{ position: 'absolute', right: -3, top: -3 }}
                disableRipple
                onClick={handleClick}
              >
                <MoreVertIcon fontSize='small' />
              </IconButton>
              <Menu
                id='long-menu'
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpenProfile}>Abrir perfil</MenuItem>
                {(kanbanList && setKanbanList && usuarioId === negocio.Negocio.VendedorId) && (
                  <MenuItem onClick={handleOpenLoseDialog}>Perdeu</MenuItem>
                )}
              </Menu>
            </Card>
          </Grid>
        )}
      </Draggable>
      <LoseDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmLose}
      />
    </>
  );
};
